<template>
  <div ref="animatable">
    <slot name="animate" />
  </div>
</template>

<script>
/**
 * Animatable (no stolen code here folks, *ahum* *ahum*)...
 *
 * When this component is mounted we construct an animatable element.
 * The constructor expects an element,
 * in our case we just get the 'animatable' reference from the element above ( ref="animatable" )
 * Whenever the element enters the viewport we add the 'animatable' class to the constructed element.
 *
 * Great! Now we can add animations to this class using CSS!
 *
 * You can use this component by using the slot functions in Vue.
 * In your template just wrap your content in an Animatable element like so:
 *
 * <Animatable>
 *   <template #animate>          <---- Note the " #animate " this tells Vue to replace the <slot> in the template above
 *     <div>My epic content</div>       with the content in this <template> tag
 *   </template>
 * </Animatable>
 *
 * Have fun!
 */
export default {
  name: 'Animatable', // eslint-disable-line
  mounted() {
    this.constructor(this.$refs.animatable);
  },
  methods: {

    /**
     * Construct an element
     */
    constructor(element) {
      const $self = this;
      const options = {
        rootMargin: '0px',
        threshold: 0.01,
      };

      $self.direction = element.dataset.direction;
      $self.observer = new IntersectionObserver(
          (entries, observer) => {
            this.observed(entries, observer, element);
          }, options);

      $self.observer.observe(element);
    },

    /**
     * Check if the constructed element is visible on the viewport
     *
     * Enable the 'else' to remove the animatable class when outside the viewport for more animation goodness.
     * @param entries
     * @param observer
     * @param element
     */
    observed(entries, observer, element) { // eslint-disable-line
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          element.classList.add(`animatable`);
        }
        else {
          element.classList.remove(`animatable`);
        }
      });
    }
  }
}
</script>
