<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ConfirmationView",
    data() {
        return {
            text: null
        }
    },
    mounted() {
        console.log(this.confirmationText)
        this.text = this.$route.params.confirmationText;
        this.$store.commit('loading/updateLoading', false);
    }
}
</script>