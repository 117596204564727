<template>

    <div v-if="todo && formId" class="modal modal--open">
        <div class="modal__inner">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="modal__inner__content">
                            <h3>{{ todo.eventData.title }}</h3>
                            <p>
                                {{ todo.eventData.message }}
                            </p>
                            <br>
                            <div class="todo-modal__buttons">
                                <router-link class="button button--small" :to="{ name: 'CreateEntryView', params: { entryType: formId, type: type } }">
                                    <i class="icon-plus"></i> <span>{{ todo.eventData.button }}</span>
                                </router-link>
                                <router-link class="button button--small" :to="{ name: 'EntrySuccessView', query: { type: formId } }">
                                    <span>Afsluiten</span> <i class="icon-chevron-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'TodoModalView',
    data() {
        return {
            todo: null,
            formId: null,
            type: null
        }
    },
    mounted() {

        if (this.$route.params.todo === undefined || this.$route.params.todo === 'undefined') {
            this.$router.push({ name: 'EntryTypesView' });
            return;
        }

        console.log(this.$route.params)

        this.todo = JSON.parse(this.$route.params.todo);
        this.formId = this.$route.params.formId;
        this.type = this.$route.params.type;
    }
}
</script>