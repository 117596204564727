<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="entry-success">
                    <h1>Registratie compleet</h1>
                    <p>
                        Uw ingave werd succesvol bewaard.
                    </p>

                    <router-link v-if="this.$route.query.type"
                        :to="{ name: 'CreateEntryView', params: { entryType: this.$route.query.type } }" class="button button--center">
                        Nog een ingave toevoegen
                    </router-link>
                    <router-link :to="{ name: 'EntryTypesView' }" class="button button--white button--center">Naar beginpagina</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "EntrySuccessView",
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.updateBreadcrumbs();
    },
    methods: {

        updateBreadcrumbs () {

            const breadcrumbs = this.breadcrumbs;
            breadcrumbs.form = 'Registratie compleet';
            breadcrumbs.formStep = null;

            this.$store.commit('breadcrumbs/updateBreadcrumbs', breadcrumbs);
        }
    }
}
</script>