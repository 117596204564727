const formGroupMixin = {

    props: {
        'states': {
            type: Array,
            default: [],
            required: true,
        },
        'actualValues' : {
            type: Array,
            default: [],
            required: true,
        }
    },
    watch: {

        actualValues: {
            handler() {
                this.checkStates();
            },
            deep: true
        }
    },
    mounted() {
        this.checkStates();
    },
    methods: {

        checkStates () {

            this.states.forEach(state => {

                if (state.visibility === undefined) return;

                let conditionResults = [];

                // loop over every condition in the current state
                state.conditions.forEach((condition) => {

                    // set the parameters needed to determine the outcome of the condition
                    // check the response in the browser for more information on these conditions :-)
                    const widgetId = condition[0];
                    const value = condition[1];
                    const operator = condition[2];
                    const currentValue = this.actualValues[widgetId];

                    // check if the provided condition matches anything
                    const result = this.$operationHandler(currentValue, operator, value).result();

                    // add result to array
                    conditionResults.push(result);
                });

                // now we have the results of every condition in this state stored in an array
                // if one of the stored results is false, this means not all conditions are met and thus we don't show the widget
                // if no false value is found, we can show the widget
                let result = conditionResults.includes(false);

                Object.keys(state.visibility).forEach((id) => {

                    const element = document.querySelector('[data-state-input=' + id + ']');

                    if (element) {

                        if (result === false) {
                            element.hidden = false;
                        }
                        else {
                            element.hidden = true;
                        }
                    }
                });
            });
        }
    }
}

export default formGroupMixin;