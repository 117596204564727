/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Api } from "@/api/Api";
import CustomInputs from './components/form/inputs/CustomInputs';
import EntryForms from './components/form/entry-forms/EntryForms';
import Animate from './plugins/Animatable/Animatable';
import  VueGoogleMaps from './plugins/vue-google-maps'
import Helpers from "@/helpers/Helpers";
import './registerServiceWorker';

// create app
const app = createApp(App);

// configure packages
app.config.globalProperties.$api = Api;
app.use(store);
app.config.globalProperties.$store = store;
app.use(router);
app.use(CustomInputs);
app.use(EntryForms);
app.use(Animate);
app.use(Helpers);
app.use(VueGoogleMaps, {
    load: {
        key: `${googleMapsKey}`,
    }
});

// mount
app.mount('#app')