<template>
    <div class="input-multiple__modal" :class="{ 'input-multiple__modal--visible': visible }">
        <div class="input-multiple__modal__backsplash" :class="{ 'input-multiple__modal__backsplash--visible': visible }"></div>
        <div v-if="request" class="input-multiple__modal__inner">

            <a @click="this.$emit('hide-modal')">
                <i class="icon-close"></i>
            </a>

            <span v-for="field in attributesCopy.fields" :key="field.id">

                <p :ref="'error-' + field.id" class="form-input-error no-display">
                    Gelieve dit veld niet leeg te laten
                </p>
                <InputComponent
                    :widget="getWidgetForId(field.id)"
                    @update-widget-in-form="updateItem"
                />
            </span>

            <div class="input-multiple__modal__submit">
                <a @click="createItem" class="button button--small">
                    <span>Toevoegen</span> <i class="icon-chevron-right"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import InputComponent from "@/components/form/inputs/Input";
export default {
    name: 'InputMultipleModal',
    components: {
        InputComponent
    },
    data() {
        return {
            request: {},
            errorAmount: 0,
            attributesCopy: this.attributes
        }
    },
    props: [
        'visible',
        'attributes',
        'values',
        'editItemIndex',
        'existingItems',
        'id'
    ],
    mounted() {

        if (this.existingItems.length && this.attributesCopy.fields[0].id === 'material') {
            this.attributesCopy.fields[0].formControl.value = parseInt(this.existingItems[0]['material']);
        }

        this.createRequest();
    },
    methods: {

        /**
         * Ripped directly from FormMixin.js
         * Also added a custom property 'multipleInputLabel'
         */
        createRequest () {
            const request = {};

            this.attributesCopy.fields.forEach((widget) => {
                request[widget.id] = widget.formControl.value;
            });

            request['multipleInputLabel'] = '';

            this.request = request;
        },

        /**
         * Ripped directly from FormMixin.js
         */
        updateItem (e) {

            // if we have an object, encode it
            if (typeof e.value === "object") {
                this.request[e.id] = JSON.stringify(e.value);
                return;
            }

            this.request[e.id] = e.value;
        },

        /**
         * If we have no errors we will emit an event containing the updated 'request' property we made earlier
         *
         * If we are in 'edit mode' eg. we have a 'values' prop we emit an overwrite event containing the
         * updated 'request' property but also the 'editItemIndex' prop, this way we can overwrite the item in the
         * parent component.
         */
        createItem () {
            this.validate();

            if (this.errorAmount !== 0) {
                return;
            }

            // generate a label, we only do this to show in the frontend
            this.request['multipleInputLabel'] = this.generateFrontendLabel();

            // if we have values we are in edit mode, we should emit a different event in this case
            if (this.values && (this.editItemIndex !== null)) {

                const values = {
                    request: this.request,
                    index: this.editItemIndex
                }

                this.$emit('overwrite-item', values);
                return;
            }

            this.$emit('create-item', this.request);
        },

        /**
         * Just check if every field is filled in
         */
        validate () {

            this.errorAmount = 0;

            this.attributesCopy.fields.forEach((item) => {

                if (this.request[item.id] === null || this.request[item.id] === 'null' || this.request[item.id] === '') {
                    this.errorAmount++;
                    this.$refs['error-' + item.id][0].classList.remove('no-display');
                }
                else {
                    this.$refs['error-' + item.id][0].classList.add('no-display');
                }
            });
        },

        /**
         * Returns a widget object based on a given id
         *
         * @param widgetId
         * @returns {*}
         */
        getWidgetForId(widgetId) {
            const selectedWidget = this.attributesCopy.fields.filter(widget => widget.id === widgetId);

            // if we have values, overwrite the default value because we are in editing mode
            if (this.values) {

                const isNumber = Number(selectedWidget[0].formControl.value);

                // We sometimes need an integer but the value of our widget is usually a string
                // Here we check whether our string is a numerical value, if this is the case, parse in to an integer
                if (Number.isInteger(isNumber) && isNumber > 0) {
                    selectedWidget[0].formControl.value = parseInt(this.values[widgetId]);
                }
                else {
                    selectedWidget[0].formControl.value = this.values[widgetId];
                }
            }
            // else we default to null
            else {
                selectedWidget[0].formControl.value = null;
            }

            return selectedWidget[0];
        },

        /**
         * Generate a little label to use in the InputMultiple.vue component
         * This label is purely for presentation purposes and isn't handled by the backend in any way :-)
         *
         * Basically, we just take the first 2 widgets in our modal and glue their inputted values to each other
         *
         * @returns {string}
         */
        generateFrontendLabel () {

            let label = '';
            const requestArray = Object.keys(this.request);

            for (let i = 0; i < 3; i++) {

                const widget = this.getWidgetForId(requestArray[i]);

                if (widget && widget.formControl.selectOptions) {

                    widget.formControl.selectOptions.forEach(item => {

                        if (item.value == this.request[requestArray[i]]) {
                            label += item.label + ' ';
                        }
                    });
                }
                else {
                    if (!Number.isNaN(Number(this.request[requestArray[i]]))) {
                        label += '<span>(' + this.request[requestArray[i]] + ')</span>' + '<br>';
                    }
                    else {
                        label += '<span>' + this.request[requestArray[i]] + '</span>' + '<br>';
                    }
                }
            }

            return label;
        }
    }
}
</script>