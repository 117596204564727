<template>
    <div v-if="headerHeight > 0"
         class="header__menu" :class="{ 'header__menu--open': open }" :data-top="headerHeight">

        <router-link :to="{ name: 'EntryTypesView' }">Home</router-link><br>
        <br>
        <a @click="logout">Afmelden</a>
    </div>
</template>

<script>
export default {
    name: 'CGMenu',
    props: [
        'open',
        'headerHeight'
    ],
    watch: {
        /**
         * If the menu is visible add an eventListener to the document to listen for clicks
         * Why we do this you ask? Read closeMenu in the methods hook below
         */
        open() {
            if (this.open) {
                document.addEventListener('mousedown', this.closeMenu);
            }
            else {
                document.removeEventListener('mousedown', this.closeMenu);
            }
        },

        $route() {

            if (this.open) {
                this.$emit('closeMenu');
            }
        }
    },
    methods: {

        /**
         * Emits an event to change the 'open' prop in CGHeader
         *
         * This method is called when the document eventListener is triggered in the watch hook above
         * If our clickEvent happens in an element which has .header as a parent, do nothing
         * Else we close the menu
         */
        closeMenu (e) {
            if ( ! e.target.closest('.header')) {
                this.$emit('closeMenu');
            }
        },

        /**
         * Logout the user, ends the session
         * Also update the store
         *
         * @returns {Promise<Response>}
         */
        async logout () {

            return await this.$api.user.logout()
                .then(data => {
                    this.form = data;

                    this.$store.commit('user/updateAuthenticated', false);
                    this.$emit('logout');
                    this.$router.push({ name: 'Login' });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
        }
    }
}
</script>