<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>


        <div class="input-multiple__items">
            <div v-for="(item, index) in items" :key="index" class="input-multiple__item">
                <div v-html="item.multipleInputLabel" class="input-multiple__item__label"></div>
                <div>
                    <a @click="editItem(index)" class="input-multiple__item__edit"><i class="icon-edit"></i></a>
                    <a @click="deleteItem(index)" class="input-multiple__item__delete"><i class="icon-trash"></i></a>
                </div>
            </div>
        </div>

        <div class="input-multiple__add">
    <!--        <a class="input-multiple__add__button button button&#45;&#45;small">-->
    <!--            <i class="icon-plus"></i><span>Toevoegen</span>-->
    <!--        </a>-->

            <a class="button button--large" @click="openModal">
                <span>Toevoegen</span> <i class="icon-plus"></i>
            </a>
        </div>

        <InputMultipleModal v-if="visible"
            :attributes="attributes"
            :visible="visible"
            :values="editSpecificItem"
            :editItemIndex="editItemIndex"
            :existingItems="items"
            :id="id"

            @hide-modal="hideModal"
            @create-item="createItem"
            @overwrite-item="overwriteItem"
        />

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';
import InputMultipleModal from "@/components/form/inputs/custom/partials/InputMultipleModal";

export default {
    name: 'InputMultiple',
    mixins: [FormElementMixin],
    data() {
        return {
            visible: false,
            items: [],
            editSpecificItem: null,
            editItemIndex: null
        }
    },
    components: {
        InputMultipleModal
    },
    methods: {

        /**
         * Add new item to items array and update the value in the form component
         */
        createItem (e) {

            this.items.push(e);
            this.updateItemsInForm();

            this.hideModal();
        },

        /**
         * When overwriting an item look for the item in the array with the index we receive from 'e' and overwrite it
         */
        overwriteItem (e) {
            this.items[e.index] = e.request;
            this.updateItemsInForm();

            this.hideModal();
        },

        /**
         * Remove an item from items and update the value in the form component
         */
        deleteItem (index) {

            this.items.splice(index, 1);
            this.updateItemsInForm();

            this.hideModal();
        },

        /**
         * We set the 'visible' property to true, this will trigger the modal to appear.
         * But we also set the 'editItemIndex' property to the index of the item in the array we want to edit.
         * This way we can send this index back when we are done editing and we know which item to overwrite :-)
         */
        editItem (index) {
            this.editSpecificItem = this.items[index];
            this.editItemIndex = index;
            this.visible = true;
        },

        /**
         * Just open the modal
         */
        openModal () {
            this.visible = true;
            this.editSpecificItem = null;
            this.editItemIndex = null;
        },

        /**
         * Hide the modal
         */
        hideModal () {
            this.visible = false;
            this.editSpecificItem = null;
            this.editItemIndex = null;
        },

        /**
         * Custom emit function
         */
        updateItemsInForm () {

            const value = {
                value: this.items,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        },
    }
}
</script>