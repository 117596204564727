<template>

    <div v-if="groups === false" class="form-group form-group--active">
        <div v-for="widget in widgets" :key="widget.id">

            <InputComponent
                :widget="widget"
                @update-widget-in-form="emitValue"
            />
        </div>

        <button @click="$emit('submit-form')">
            <span>{{ submitButtonLabel }}</span> <i class="icon-arrow"></i>
        </button>
    </div>

    <div v-else class="form--groups">
        <div v-if="groups">

            <div v-if="groupError" class="form-group-error">{{ errorMessage }}</div>

            <div v-for="(group, index) in groups" :key="group.id">

                <div :class="{ 'form-group--active': index === activeGroup }" class="form-group">
                    <div v-for="widgetId in group.widgetIds" :key="widgetId">
                        <InputComponent
                            :widget="getWidgetForId(widgetId)"
                            @update-widget-in-form="emitValue"
                            @hide-buttons="toggleButtons"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="form-buttons-wrapper" v-if="!hideButtons">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="form-buttons">
                            <span v-if="activeGroup !== 0" class="form-button-prev">
                                <button class="button--small" @click="previousGroup" id="form-group-previous">
                                    <i class="icon-chevron-left"></i><span>Vorige</span>
                                </button>
                            </span>
                                <span v-if="activeGroup !== (groupAmount - 1)" class="form-button-next">
                                <button class="button--small" @click="nextGroup" id="form-group-next">
                                    <span>Volgende</span> <i class="icon-chevron-right"></i>
                                </button>
                            </span>
                            <span v-if="activeGroup === (groupAmount - 1)">
                                  <button class="button--small" @click="submit">
                                    <span>{{ submitButtonLabel }}</span> <i class="icon-chevron-right"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import InputComponent from "@/components/form/inputs/Input";
import FormGroupsMixin from "@/components/form/mixins/FormGroupsMixin";
import FormStatesMixin from "@/components/form/mixins/FormStatesMixin";

export default {
    name: 'FormComponent',
    mixins: [FormGroupsMixin, FormStatesMixin],
    components: {
        InputComponent
    },
    data() {
        return {
            groupError: false,
            errorMessage: `${groupErrorMessage}` // eslint-disable-line
        }
    },
    props: [
        'submitButtonLabel',
        'actualValues',
        'activeGroupProp'
    ],
    watch: {
        activeGroupProp() {
            this.activeGroup = this.activeGroupProp;
        }
    },
    methods: {

        /**
         * Because we use this generic template we need to emit the event from our input templates another time
         *
         * @param e
         */
        emitValue (e) {
            this.$emit('update-widget-in-form', e)
        },

        /**
         * Returns a widget object based on a given id
         *
         * @param widgetId
         * @returns {*}
         */
        getWidgetForId(widgetId) {
            const selectedWidget = this.widgets.filter(widget => widget.id === widgetId);
            return selectedWidget[0];
        },

        submit () {
            this.$emit('submit-form');
        }
    }
}
</script>