const state = () => ({
    loading: true
});

const getters = {

    getLoading(state) {
        return state.loading;
    }
}

const mutations = {
    updateLoading (state, loading) {
        state.loading = loading;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
};