<template>
    <div v-if="form" class="form">

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <FormComponent
            :title="title"
            :groups="groups"
            :widgets="widgets"
            :error="hasErrors"
            :states="states"
            :submitButtonLabel="submitButtonLabel"
            :actualValues="request"
            :activeGroupProp="activeGroup"

            @submit-form="checkCyclus2"
            @update-widget-in-form="updateRequest"
            @reset-error-boolean="hasErrors = false"
        />
    </div>

    <div class="modal" :class="{ 'modal--open': open === true }">
        <div class="modal__inner">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="modal__inner__content">
                            <h3>Cyclus 2</h3>
                            <p>
                                Ben je zeker dat je de ingave wil opslaan zonder een 2e cyclus in te voeren?
                            </p>
                            <br>
                            <br>
                            <div class="modal__buttons">
                                <button @click="submitForm">
                                    Ja, registratie afronden
                                </button>
                                <button @click="enableCyclus2Widget" class="button--white">
                                    Cyclus 2 toevoegen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import FormMixin from "@/components/form/mixins/FormMixin";
import FormComponent from "@/components/form/Form";
import PlateTestFormMixin from '@/components/form/entry-forms/mixins/PlateTestFormMixin'

export default {
    name: 'PlateTestForm',
    components: {
        FormComponent
    },
    mixins: [FormMixin, PlateTestFormMixin],
    data() {
        return {
            path: 'entry/' + this.entryType,
            params: "?yardId=" + this.yardId,
            toggleId: `${plateTestFormToggleId}`, // eslint-disable-line
            open: false,
            activeGroup: 0
        }
    },
    props: [
        'entryType',
        'yardId'
    ],
    created() {
        this.ajaxGetForm(this.path, this.params);
    },
    methods: {

        /**
         * Submit the form
         *
         * @returns {Promise<Response>}
         */
        async submitForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.post(JSON.stringify({ 'formValues': this.request }), this.path, this.params)
                .then(data => {

                    if (!data.success) {
                        this.$store.commit('loading/updateLoading', false);

                        // if we have errors we can replace our form with the form the backend sends back
                        this.form = data;
                        this.hasErrors = true;
                        this.scrollToTop();
                        return;                    }

                    console.log('Post successful');

                    // when the post is successful we also need to update our store in this case
                    this.$store.commit('loading/updateLoading', false);
                    this.$router.push({ name: 'EntrySuccessView', query: { type: this.entryType } });
                });
        },

        checkCyclus2 () {

            const toggleWidget = document.getElementById(this.toggleId);

            if (toggleWidget) {

                if (toggleWidget.value != 'true') {

                    this.open = true;
                    return;
                }
            }

            this.submitForm();
        },

        enableCyclus2Widget () {

            const toggleWidget = document.getElementById(this.toggleId);

            this.open = false;

            toggleWidget.click();
            this.activeGroup = 2;
        }
    }
}
</script>