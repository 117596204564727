<template>


    <div v-for="(input, index) in inputs.inputs" :key="input.formControl.title">
        <input v-if="index !== 3"
               type="number"
               min="0"
               :id="input.id"
        >
        <input v-else
               type="text"
               class="input--no-border"
               :id="input.id"
               disabled
        >
    </div>

</template>

<script>

export default {
    name: 'InputMatrixRow',
    props: [
        'inputs',
        'value'
    ],
    data() {
        return {
            fields: [],
            average: null,
            id: null,
            loopFields: null,
            averageToShow: null
        }
    },
    watch: {
        value() {
            this.initFieldsWithValue();
        }
    },
    mounted() {
        this.init();
        this.initFields();

    },
    methods: {

        initFields() {
            this.loopFields = [];

            this.inputs.inputs.forEach(input => {

                this.loopFields.push({
                    id: input.id,
                    value: 0
                });
            });
        },

        initFieldsWithValue () {

            this.loopFields = [];

            const values = JSON.parse(this.value);
            this.inputs.inputs.forEach(input => {

                this.loopFields.push({
                    id: input.id,
                    value: values.inputs[input.id]
                });
            });
        },

        init () {

            const average = this.inputs.inputs[3];

            if (! average) return;

            this.id = average.id;

            average.attributes.calculateFields.forEach(field => {

                const calculateField = document.getElementById(field);

                if (calculateField) {

                    calculateField.oninput = this.updateAverage;
                    this.fields.push(field);
                }
            });
        },

        updateAverage () {

            let newValue = 0;
            let fieldAmount = 0;

            this.fields.forEach(field => {
                const value = document.getElementById(field).value;

                if (value) {
                    newValue += parseInt(value);
                    fieldAmount++;
                }
                else {
                    newValue += 0;
                    fieldAmount++;
                }
            });

            let averageValue = newValue / fieldAmount ? newValue / fieldAmount : null;

            if (averageValue != 0 && averageValue % 1 != 0) {
                this.averageToShow = averageValue.toFixed(2);
            }

            this.average = averageValue;
            this.updateWidget();
        },

        /**
         * Custom update method, we don't want to update our request via an input event, instead update it at the end
         * of the updateAverage method
         */
        updateWidget () {

            const widget = document.getElementById(this.id);
            widget.value = this.averageToShow;
            widget.title = this.average;

            let fieldArray = [];
            this.fields.forEach(field => {

                const matrixField = document.getElementById(field);
                fieldArray.push({
                    value: matrixField.value,
                    id: field
                })
            });

            const value = {
                value: this.average,
                fields: fieldArray,
                id: this.id
            };

            this.$emit('update-average', value);
            this.updateMedian();
        },

        updateMedian () {
            this.$emit('updateMedian');
        }
    }
}
</script>