<template>

    <div class="content">

        <div class="row justify-content-center">
            <div class="col-md-8">
                <component :is="entryType"
                    :entryType="entryType"
                    :yardId="yard.yardId"
                    :type="type"
                ></component>

            </div>
        </div>

    </div>

<!--    <PermissionModal-->
<!--        :open="locationPermission"-->
<!--    />-->

</template>

<script>
import PermissionModal from "@/components/PermissionModal";

export default {
    name: 'CreateEntryView',
    components: {
        PermissionModal
    },
    data() {
        return {
            entryType: this.$route.params.entryType,
            type: this.$route.params.type ? this.$route.params.type : null
        }
    },
    computed: {
        yard() {
            return this.$store.getters['yard/getYard'];
        },
        locationPermission() {
            return this.$store.getters['user/getLocationPermission'];
        }
    },
}
</script>