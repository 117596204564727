<template>

    <div :data-state-input="id">

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <input
            :class="{ 'input--error': error }"
            type="date"
            ref="input"
            :value="value"
            :id="id"

            @input="updateWidgetInForm"
            @click="updateWidgetInForm"
        >

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputDate',
    mixins: [FormElementMixin],
    mounted() {

        // generate new date to set default value
        const date = new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        this.$refs.input.value = date.toISOString().slice(0,10);

        // click to trigger update :p
        this.$refs.input.click();
    }
}
</script>