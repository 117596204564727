<template>
    <div class="loader" data-loader :class="{ 'loader--loading': loading }">
        <svg
            width="32.396099"
            height="32.396095"
            viewBox="0 0 32.396099 32.396095"
            fill="none"
            version="1.1"
            id="svg826"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <defs
                id="defs830" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m 15.2704,0 h 1.8553 v 2.886196 c 6.6146,0.45667 11.8958,5.76002 12.3176,12.3842 h 2.9528 v 1.8553 h -2.9627 c -0.488,6.5575 -5.7407,11.7881 -12.3077,12.2415 v 3.0289 h -1.8553 v -3.0289 c -6.56706,-0.4534 -11.81973,-5.684 -12.30771,-12.2415 H 0 l 8.10969e-8,-1.8553 H 2.95284 C 3.37463,8.646226 8.65576,3.342886 15.2704,2.886206 Z M 7.45254,17.125696 h 2.96556 v -1.8553 H 7.43759 c 0.401,-4.1536 3.68901,-7.46295 7.83281,-7.897 v 3.0447 h 1.8553 v -3.0447 c 4.1438,0.43404 7.4318,3.7434 7.8328,7.897 H 21.978 v 1.8553 h 2.9656 c -0.4617,4.0865 -3.7216,7.3252 -7.8179,7.7543 v -2.9021 h -1.8553 v 2.9021 c -4.0963,-0.4291 -7.35619,-3.6678 -7.81786,-7.7543 z"
                fill="#F15450"
                id="path824" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'AppLoader',
    props: [
        'loading'
    ],
    watch: {
        // loading() {
        // }
    }
}
</script>