<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <InputSignature />
            </div>
        </div>
    </div>

</template>

<script>

import InputSignature from "@/components/form/inputs/custom/InputSignature";
export default {
    name: 'TestComponent',
    components: {InputSignature},
}
</script>
