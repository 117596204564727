<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <ResetPasswordForm />
            </div>
        </div>
    </div>

</template>

<script>
import ResetPasswordForm from "@/components/form/ResetPasswordForm";

export default {
    name: 'ResetPasswordView',
    components: {
        ResetPasswordForm,
    },
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.breadcrumbs.dashboard = 'Wachtwoord vergeten'
        this.breadcrumbs.form = null;
        this.breadcrumbs.formStep = null;

        this.$store.commit('breadcrumbs/updateBreadcrumbs', this.breadcrumbs);
    },
}
</script>
