const state = () => ({
    label: '',
    delta: 0
});

const getters = {

    getLabel(state) {
        return state.label;
    },
    getDelta(state) {
        return state.delta;
    }
}

const mutations = {

    updateLabel (state, label) {
        state.label = label;
    },
    updateDelta (state, delta) {
        state.delta = delta;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
};