<template>
  <input :data-state-input="id"
      :class="{ 'input--error': error }"
      type="text"
      :value="value"
      :name="id"
      :id="id"
      ref="input"
      :hidden="hidden"

      @click="updateWidgetInForm"
  >
</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
  name: 'InputText',
  mixins: [FormElementMixin],
  props: [
    'hidden'
  ],
  computed: {
    yard() {
      return this.$store.getters['yard/getYard'];
    }
  },
  mounted() {
    this.$refs.input.value = this.yard.date
    this.$refs.input.click();
  },
}
</script>