const formGroupMixin = {

    props: {
        'title': {
            type: String,
            default: null,
            required: true,
        },
        'groups': {
            type: Array,
            default: [],
            required: true,
        },
        'widgets': {
            type: Array,
            default: [],
            required: false,
        },
        'error': {
            type: Number,
            default: 0,
            required: false,
        }
    },
    watch: {
        error() {
            if (this.groupAmount > 0) {
                this.handleGroupError();
            }
        }
    },
    data() {
        return {
            groupAmount: this.groups.length,
            activeGroup: 0,
            hideButtons: false
        }
    },
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.updateBreadcrumbs();
        this.hasErrors = this.error > 0;
    },
    methods: {

        /**
         * When there's an error in a form that uses groups, it's always nice to navigate to the group where the
         * error is. That's exactly what we do here
         */
        handleGroupError () {

            // if error is false, don't do anything
            if ( ! this.error) return;

            // loop over our widgets
            for (let i = 0; i < this.widgets.length; i++) {
                const widget = this.widgets[i];

                // for the first widget we find which has an error
                if (widget.formControl.hasError) {

                    // get the group this widget belongs to
                    const activeGroup = this.groups.find(group => group.widgetIds.includes(widget.id));

                    // loop over groups
                    this.groups.forEach((group, index) => {

                        // if the active group we just received has the same id as the current group in the loop
                        if (group.id === activeGroup.id) {

                            // set the active group to the current loop index
                            this.activeGroup = parseInt(index);
                        }
                    });

                    this.$emit('reset-error-boolean');
                    return;
                }
            }
        },

        previousGroup () {

            if (! this.validatePreviousGroup()) {
                this.activeGroup = this.activeGroup - 2;
            }
            else {
                this.activeGroup = this.activeGroup - 1;
            }

            this.updateBreadcrumbs();
        },

        nextGroup () {

            if (! this.validateGroup()) {
                window.scrollTo(0, 0);
                return;
            }

            this.groupError = false;

            if (! this.validateNextGroup()) {
                this.activeGroup = this.activeGroup + 2;
            }
            else {
                this.activeGroup = this.activeGroup + 1;
            }

            this.updateBreadcrumbs();
        },

        /**
         * Check if every required field is filled in on step
         *
         * @returns {boolean}
         */
        validateGroup () {
            const group = this.groups[this.activeGroup];
            let noErrors = true;

            group.widgetIds.forEach(id => {
                const widget = this.widgets.filter(x => x.id === id);

                if (widget[0].formControl.required && this.actualValues[id] === null) {
                    noErrors = false;

                    this.groupError = true;
                }
            });

            return noErrors;
        },

        validateNextGroup () {

            const group = this.groups[this.activeGroup + 1];
            let allVisible = true;
            let widgetAmount = 0;
            let widgetInvisibleAmount = 0;

            group.widgetIds.forEach(id => {

                const widgetDomElement = document.querySelector('[data-state-input=' + id + ']');
                widgetAmount++;

                if (widgetDomElement && widgetDomElement.hidden) {
                    widgetInvisibleAmount++;
                }
            });

            if (widgetAmount === widgetInvisibleAmount) {
                allVisible = false;
            }

            return allVisible;
        },

        validatePreviousGroup () {

            const group = this.groups[this.activeGroup - 1];
            let allVisible = true;
            let widgetAmount = 0;
            let widgetInvisibleAmount = 0;

            group.widgetIds.forEach(id => {

                const widgetDomElement = document.querySelector('[data-state-input=' + id + ']');
                widgetAmount++;

                if (widgetDomElement && widgetDomElement.hidden) {
                    widgetInvisibleAmount++;
                }
            });

            if (widgetAmount === widgetInvisibleAmount) {
                allVisible = false;
            }

            return allVisible;
        },

        updateBreadcrumbs () {

            const breadcrumbs = this.breadcrumbs;
            breadcrumbs.form = this.title;

            if (this.groups) {
                breadcrumbs.formStep = this.groups[this.activeGroup].title;
            }

            this.$store.commit('breadcrumbs/updateBreadcrumbs', breadcrumbs);
        },

        /**
         * Hide/show the form group buttons
         */
        toggleButtons (e) {
            this.hideButtons = e;
        },
    }
}

export default formGroupMixin;