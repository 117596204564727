<template>

    <div class="input-matrix__calculator">

        <div class="input-matrix__calculator__legend">
            <div class="input-matrix__calculator__heading">{{ attributes.pressurelabel }}</div>
            <div v-for="input in attributes.inputs" :key="input.label">
                {{ input.label }}
            </div>
        </div>
        <div class="input-matrix__calculator__right">
            <div class="input-matrix__calculator__head">
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>Gemiddeld</div>
            </div>

            <div class="input-matrix__calculator__row">
                <div v-for="inputs in attributes.inputs" :key="inputs.label" class="input-matrix__calculator__inputs">

                    <InputMatrixRow :inputs="inputs"
                                    :object="object"
                                    :value="value"

                                    @update-average="updateAverage"
                    />
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import InputMatrixRow from "@/components/form/inputs/custom/partials/InputMatrixRow";
export default {
    name: 'InputMatrixGrid',
    components: {InputMatrixRow},
    props: [
        'attributes',
        'value'
    ],
    data() {
        return {
            median: null,
            delta: null,
            averageFields: [],
            object: {
                inputs: {},
                median: 0,
                calculated: 0
            }
        }
    },
    created() {
        this.initMatrixObject();
    },
    mounted() {
        this.initGlobalDelta();
    },
    computed: {
        label() {
            return this.$store.getters['plateTest/getLabel'];
        },
        deltaValue() {
            return this.$store.getters['plateTest/getDelta'];
        }
    },
    watch: {
        value() {
            this.initMatrixObject();
        },
        deltaValue() {
            this.resetExternalFields();
        },
        label() {
            this.resetExternalFields();
        }
    },
    methods: {

        resetExternalFields () {
            const s1 = document.getElementById('s1');
            s1.value = 0;
            s1.click();

            const m1 = document.getElementById('m1');
            m1.value = 0;
            m1.click();

            const s2 = document.getElementById('s2');
            s2.value = 0;
            s2.click();

            const m2 = document.getElementById('m2');
            m2.value = 0;
            m2.click();

            const m = document.getElementById('m');
            m.value = 0;
            m.click();
        },

        initGlobalDelta () {

            const m1 = document.getElementById('m1');
            const m2 = document.getElementById('m2');

            m1.addEventListener('click', () => {
                this.updateM(m1.value, m2.value);
            });
            m2.addEventListener('click', () => {
                this.updateM(m1.value, m2.value);
            });
        },

        updateM (m1, m2) {

            const m =  document.getElementById('m');

            if (m1 && m2) {
                const value = m2 / m1;
                m.value = value.toFixed(2);
                m.click();
            }
            else {
                m.value = 0;
                m.click();
            }
        },

        initMatrixObject () {

            const responseValue = this.value ? JSON.parse(this.value) : 0;

            Object.values(this.attributes.inputs).forEach(input => {

                input.inputs.forEach(element => {

                    if (element.id.includes('avg')) {

                        this.averageFields.push(element);
                    }

                    let value = null;

                    if (responseValue) {

                        value = responseValue.inputs[element.id];
                    }

                    this.object.inputs[element.id] = value ? value : 0;
                });
            });
        },

        /**
         * Update an average field based on what we receive from InputMatrixRow.vue
         *
         * @param value
         */
        updateAverage (value) {

            value.fields.forEach(item => {

                this.object.inputs[item.id] = item.value;
            });

            this.object.inputs[value.id] = value.value;

            this.updateDelta();
            this.emit();
        },

        emit () {
            this.$emit('valueUpdated', JSON.stringify(this.object));
        },

        /**
         * Calculate the delta value for this matrix
         */
        updateDelta () {

            const deltaField = document.getElementById('s' + this.attributes.cycle);
            let delta = 0;

            // forgive me for I have sinned :'-)
            // to work around some constraints I had no choice but to do something filthy...
            //
            // in InputMatrixRow, we set the value of the inputs we fetch below, these are floats with 2 decimals
            // because that looks better.
            // but we need the full float (which has like 10 decimals) to calculate delta here,
            // that's why I add the full float to the title tag on these inputs below
            const value1 = document.getElementById(this.attributes.delta_field_ids[0]).title;
            const value2 = document.getElementById(this.attributes.delta_field_ids[1]).title;

            if (value1 && value2) {
                delta = value1 - value2;
            }

            // if (delta != 0 && delta % 1 != 0) {
                // delta = delta.toFixed(2);
            // }

            this.updateDelta2(delta);

            deltaField.value = delta.toFixed(2);
            deltaField.click();
        },

        updateDelta2 (delta1) {

            if (parseInt(this.deltaValue) !== 0 && delta1 !== 0) {
                const delta2Field = document.getElementById('m' + this.attributes.cycle);
                let delta2 = parseInt(this.deltaValue) / delta1

                if (delta2 != 0 && delta2 % 1 != 0) {
                    delta2 = delta2.toFixed(2);
                }

                delta2Field.value = delta2;
                delta2Field.click();
            }
        }
    }
}
</script>