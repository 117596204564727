const formMixin = {

    data() {
        return {
            form: null,
            request: null,
            id: null,
            title: null,
            description: null,
            widgets: null,
            states: null,
            errorCount: null,
            submitButtonLabel: null,
            error: null,
            groups: null,
            hasErrors: false
        }
    },
    watch: {

        /**
         * We need to wait for our form to load, initially we set it to null
         * When the form is loaded the 'form' property will be changed and we can now set our properties
         */
        form () {
            this.setProperties();
            this.createRequest();
        }
    },
    methods: {

        /**
         * Call our form via ajax, called in the created hook
         *
         * @returns {Promise<Response>}
         */
        async ajaxGetForm(formPath, params = null) {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.get(formPath, params)
                .then(data => {
                    this.form = data;
                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    this.$store.commit('loading/updateLoading', false);
                    console.log(error);
                });
        },

        /**
         * Called when the form is loaded, sets all properties we need
         */
        setProperties () {
            // if these properties already have an assigned value, don't overwrite
            if ( ! this.id) {
                this.id = this.form ? this.form.id : null;
            }
            if ( ! this.title) {
                this.title = this.form ? this.form.title : null;
            }
            if ( ! this.description) {
                this.description = this.form ? this.form.desc : null;
            }

            // set or overwrite these properties
            this.widgets = this.form ? this.form.form.widgets : null;
            this.states = this.form ? this.form.form.states : null;
            this.errorCount = this.form ? this.form.form.errorCount : null;
            this.submitButtonLabel = this.form ? this.form.form.submitButtonLabel : null;
            this.error = this.form.form.error ? this.form.form.error : null;
            this.groups = this.form.form.layout.groups ? this.form.form.layout.groups : false;
        },

        /**
         * Create the request property, this method is called when the form is loaded
         * We create an object with our widget ids and their values as properties within
         *
         * As the form is filled in, the 'request' property will be filled in too :-)
         * This way we have nice object to send back to the backend on submit
         */
        createRequest () {
            const request = {};
            this.widgets.forEach((widget) => {
                request[widget.id] = widget.formControl.value;
            });

            this.request = request;
        },

        /**
         * When an input is being filled in, we also need to update our 'request' property!!
         * We update the correct property based on the id and value we send in our input component
         *
         * @param e
         */
        updateRequest (e) {

            // if we have an object, encode it
            if (typeof e.value === "object") {
                this.request[e.id] = JSON.stringify(e.value);
                return;
            }

            this.request[e.id] = e.value;
        },

        scrollToTop () {
            window.scrollTo(0, 0);
        }
    }
}

export default formMixin;