import * as Vue from "vue/dist/vue.esm-bundler.js";

import ThicknessMeasurementForm from '@/components/form/entry-forms/ThicknessMeasurementForm'
import ImageForm from '@/components/form/entry-forms/ImageForm'
import HouseInstallationForm from "@/components/form/entry-forms/HouseInstallationForm";
import PlateTestForm from "@/components/form/entry-forms/PlateTestForm";
import ConcreteForm from "@/components/form/entry-forms/ConcreteForm";
import CoreDrillForm from "@/components/form/entry-forms/CoreDrillForm";
import AdditionalWorkForm from "@/components/form/entry-forms/AdditionalWorkForm";

/**
 * Entry Forms
 *
 * Because we don't want to end up in .vue file hell, I created this module to bundle every 'create entry' form type.
 * In CreateEntryView.vue we will compile the components defined below where the
 */
export default {
    install: (app) => { // eslint-disable-line

        app.component('diktemeting', ThicknessMeasurementForm); // eslint-disable-line
        Vue.compile('<diktemeting />');

        app.component('foto', ImageForm); // eslint-disable-line
        Vue.compile('<foto />');

        app.component('huisaansluiting', HouseInstallationForm); // eslint-disable-line
        Vue.compile('<huisaansluiting />');

        app.component('plaatproef', PlateTestForm); // eslint-disable-line
        Vue.compile('<plaatproef />');

        app.component('asfaltbon', ConcreteForm); // eslint-disable-line
        Vue.compile('<asfaltbon />');

        app.component('kernboring', CoreDrillForm); // eslint-disable-line
        Vue.compile('<kernboring />');

        app.component('meerwerk', AdditionalWorkForm); // eslint-disable-line
        Vue.compile('<meerwerk />');
    },
};