<template>

    <div class="image-input" :data-state-input="id">
        <div>
            <img class="image-input__img" src="/src/shared/img/image-svg.svg" alt="Image upload">

            <p v-if="error" class="form-input-error">
                {{ error }}
            </p>

            <button class="button--big-icon" @click="selectInput">
                <span><i :class="'icon-foto'"></i> <b>Foto maken of uploaden</b></span>
                <input @change="fileUpload" type="file" hidden ref="input" id="input" :accept="attributes.accept">
            </button>
        </div>
    </div>

    <input
        :class="{ 'input--error': error }"
        type="file"
        :value="value"
        :name="id"
        :id="id"
        hidden
        ref="upload"
        :accept="attributes.accept"

        @input="updateWidgetInForm"
    >
</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';
import { Html5Qrcode } from "html5-qrcode"

export default {
    name: 'InputImage',
    mixins: [FormElementMixin],
    methods: {

        /**
         * When we click the <button> above, we actually want to trigger the upload <input>
         */
        selectInput () {
            this.$refs.input.click();
        },

        /**
         * When a file is uploaded, scan it for barcodes
         *
         * @param e
         */
        fileUpload (e) {

            const html5QrCode = new Html5Qrcode('input');

            const imageFile = e.target.files[0];
            // Scan QR Code
            html5QrCode.scanFile(imageFile, true)
                .then(decodedText => {
                    this.updateBarcode(decodedText);
                })
                .catch(err => {
                    console.log(`Error scanning file. Reason: ${err}`)
                });

            this.updateFileUploadWidget();
        },

        /**
         * The point of this component is scanning images for a barcode/qr-code
         * When we detect one, look for our barcode input and update its value
         */
        updateBarcode(barcode) {

            const barcodeInput = document.querySelector('#barcode');

            if (barcodeInput) {

                barcodeInput.value = barcode;
                barcodeInput.click();
            }
        },

        /**
         * We also want to make sure the uploaded image is uploaded to the image upload widget when it's present
         * That's why we have to do a little workaround and copy the files from this upload to the image upload component
         */
        updateFileUploadWidget () {

            const fileUpload = document.querySelector('#image');
            const fileUploadTrigger = document.querySelector('#uploadTrigger');
            const nextGroupButton = document.querySelector('#form-group-next');
            const upload = this.$refs.input;

            if (fileUpload) {

                fileUpload.files = upload.files;
                fileUploadTrigger.click();

                // go to next group after updating barcode field
                if (nextGroupButton) {

                    nextGroupButton.click();
                }
            }
        }
    }
}
</script>