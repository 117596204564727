<template>

    <div :data-state-input="id">

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <span class="checkbox-label">
            <input
                :class="{ 'input--error': error }"
                type="checkbox"
                :value="value"
                :name="id"
                :id="id"
                hidden

                @click="updateWidgetInForm"
            >

            <label :for="id">
                {{ title }}<i v-if="required">*</i>
            </label>
        </span>
    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputCheckbox',
    mixins: [FormElementMixin]
}
</script>