<template>

    <div :data-state-input="id">

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <span class="toggle">
            <label :for="id" :class="{ 'active': toggleValue }">
                {{ title }}<i v-if="required">*</i>
            </label>
            <input
                :class="{ 'input--error': error }"
                type="checkbox"
                :value="toggleValue"
                :name="id"
                :id="id"
                ref="input"
                hidden

                @click="updateMatrixState"
            >
        </span>
    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputMatrixCheckbox',
    mixins: [FormElementMixin],
    data() {
        return {
            toggleValue: false
        }
    },
    methods: {

        updateMatrixState () {
            this.toggleValue = ! this.toggleValue;
            this.customUpdateWidgetInForm();
        },

        /**
         * customUpdateWidgetInForm
         */
        customUpdateWidgetInForm () {

            const value = {
                value: this.toggleValue,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        }
    }
}
</script>