const state = () => ({
    yard: {},
    entryTypes: {}
});

const getters = {

    getYard(state) {
        return state.yard;
    },
    getEntryTypes(state) {
        return state.entryTypes;
    }
}

const mutations = {

    updateYard (state, yard) {
        state.yard = yard;
    },
    updateEntryTypes (state, entryTypes) {
        console.log('hier')
        state.entryTypes = entryTypes;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
};