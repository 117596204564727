<template>
    <div class="selected-yard">
        <div class="selected-yard__info">
            <div>
                <span class="selected-yard__info__label">Werf:</span> {{ yard.yardName }}
            </div>
            <div>
                <span class="selected-yard__info__label">Datum van ingave:</span> {{ new Date(yard.date).toLocaleDateString('nl-BE') }}
            </div>
        </div>
        <div class="selected-yard__edit">
            <router-link to="select-yard">
                <i class="icon-edit"></i>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectedYard',
    computed: {
        yard() {
            return this.$store.getters['yard/getYard'];
        }
    }
}
</script>