<template>
    <div class="modal" :class="{ 'modal--open': open === false }">
        <div class="modal__inner">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="modal__inner__content">
                            <h3>Uw locatie kon niet worden opgehaald</h3>
                            <p>
                                Om deze app te kunnen gebruiken is het belangrijk dat we uw locatiecoördinaten kunnen bepalen.
                                Dit is nodig om de juiste coördinaten aan de registraties te kunnen koppelen.
                            </p>
                            <br>
                            <p>
                                Mogelijk heeft u op dit toestel geen toestemming verleend aan de browser om uw locatie te mogen bepalen.
                                Ga naar de App-instellingen op uw mobiel toestel en zet locatiebepaling aan voor de browser waarmee u deze site bezoekt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PermissionModal',
    props: [
        'open'
    ],
    created() {
        if (window.chrome) {
            this.changePermissionInStore();
        }
    },
    methods: {

        /**
         * Most browsers already support the new Permissions API (except Safari and IE (surprise surprise)).
         * Because this API is stable in Chrome, we currentently only use it in Chrome
         * If this API is supported we can listen for changes to the location sharing permissions and
         * change our views accordingly in real-time
         */
        changePermissionInStore () {

            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {

                // add event listener to permissionStatus
                permissionStatus.onchange = (state) => {

                    // store whether permission is granted or not
                    if (state.target.state === 'granted') {
                        this.$store.commit('user/updateLocationPermission', true);
                    }
                    else {
                        this.$store.commit('user/updateLocationPermission', false);
                    }
                };
            });
        }
    }
}
</script>