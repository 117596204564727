import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index';

import LoginView from '../views/LoginView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import RecoverPasswordView from '../views/RecoverPasswordView.vue'
import YardSelectView from '../views/YardSelectView.vue'
import EntryTypesView from "@/views/EntryTypesView";
import CreateEntryView from "@/views/CreateEntryView";
import AllowGeolocationView from "@/views/AllowGeolocationView";
import TestComponent from "@/views/TestComponent";
import EntrySuccessView from "@/views/EntrySuccessView";
import TodoModalView from "@/views/TodoModalView";
import ConfirmationView from "@/views/ConfirmationView";

const routes = [
    {
        path: '/:lang?/login',
        name: 'Login',
        component: LoginView,
        meta: { requiresAuth: false }
    },
    {
        path: '/:lang?/reset-password',
        name: 'ResetPassword',
        component: ResetPasswordView,
        meta: { requiresAuth: false }
    },
    {
        path: '/:lang?/password-recovery',
        name: 'RecoverPassword',
        component: RecoverPasswordView,
        meta: { requiresAuth: false }
    },
    {
        path: '/:lang?/bevestiging',
        name: 'ConfirmationView',
        component: ConfirmationView,
        meta: { requiresAuth: false }
    },
    {
        path: '/:lang?/select-yard',
        name: 'YardSelect',
        component: YardSelectView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/select-entry-type',
        name: 'EntryTypesView',
        component: EntryTypesView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/create-entry/:entryType/:type?',
        name: 'CreateEntryView',
        component: CreateEntryView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/home',
        name: 'Home',
        component: EntryTypesView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/success/add',
        name: 'TodoModalView',
        component: TodoModalView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/success',
        name: 'EntrySuccessView',
        component: EntrySuccessView,
        meta: { requiresAuth: true }
    },
    {
        path: '/:lang?/geolocation',
        name: 'AllowGeolocationView',
        component: AllowGeolocationView,
        meta: { requiresAuth: true, geolocation: true }
    },
    {
        path: '/:lang?/testing',
        name: 'TestComponent',
        component: TestComponent,
        meta: { requiresAuth: true, geolocation: true }
    },
    {
        path: '/:catchAll(.*)',
        name: 'Home',
        component: YardSelectView,
        meta: { requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

/**
 * Before each route change we check a couple of things:
 *
 * First of all, we get the authentication status from our store.
 * Then, we get the requiresAuth meta from our route, this contains a boolean and tells us whether the user
 * needs to be authenticated to access this route.
 *
 * If the user is authenticated and the route requires authentication, proceed !!
 * If the user is not authenticated and the route requires authentication, go to login page
 * If the user is authenticated but the route doesn't require authentication, go to select yard page
 * Fallback, just go to next page.
 */
router.beforeEach((to, from, next) =>
{

    const auth = store.getters['user/getAuthenticated'];
    const locationPermission = store.getters['user/getLocationPermission'];

    // this route requires auth, check if logged in
    if (to.meta.requiresAuth && auth === true) {

        next();
    }
    else if (to.meta.requiresAuth === true
            && auth === false)  {

        console.log('Cannot navigate to this route, you need to be authenticated!');
        next({
            path: router.push({ name: 'Login' }),
            query: { redirect: to.fullPath }
        });
    }
    else if (to.meta.requiresAuth === false
            && auth === true)  {

        console.log('Cannot navigate to this route, you are already authenticated!');
        next({
            path: router.push({ name: 'YardSelect' }),
            query: { redirect: to.fullPath }
        });
    }
    else {
        next();
    }
});

export default router
