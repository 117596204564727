<template>

    <div :data-state-input="id">

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <span v-for="option in selectOptions" :key="option.label" class="input-radio-wrapper">
            <input
                type="radio"
                :value="option.value"
                :name="option.label"
                :id="option.label"
                :checked="checked === option.label"
            >

            <label :for="option.label" :name="option.label" @click="updateRadioButtons(option.label, option.value)">
                {{ option.label }}
            </label>
        </span>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputRadio',
    mixins: [FormElementMixin],
    data() {
        return {
            checked: null
        }
    },
    methods: {

        /**
         * Because we have to loop over our selectOptions we can't rely on the native radio button handling
         * because all our <input> tags have a wrapper :-(
         *
         * To work around this we uncheck the radio buttons whenever another one is clicked by checking whether the
         * 'checked' property is equal to the id of the clicked <input>
         *
         * #Hacks
         *
         * @param radioId
         * @param radioValue
         */
        updateRadioButtons (radioId, radioValue) {

            this.checked = radioId;

            const value = {
                value: radioValue,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        }
    }
}
</script>