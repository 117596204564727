<template>

    <div class="input-multiple__modal" :class="{ 'input-multiple__modal--visible': modalVisible }">
        <div class="input-multiple__modal__backsplash" :class="{ 'input-multiple__modal__backsplash--visible': modalVisible }"></div>
        <div v-if="initialized" class="input-multiple__modal__inner--maps">

            <div>
                <h3>
                    Klik op de kaart om de locatie in te stellen, of versleep het pinpoint om te wijzigen.<br>
                    Sluit de kaart om verder te gaan, de gekozen locatie wordt automatisch bewaard.

                </h3>

                <a @click="this.$emit('hide-modal')" class="input-multiple__modal__close--maps">
                    <i class="icon-close"></i>
                </a>
            </div>

            <div v-if="center && marker" class="input-multiple__modal__maps">

                <GMapMap
                    ref="myMapRef"
                    :center="center"
                    :zoom="zoom"
                    map-type-id="terrain"
                    :options="{
                        scaleControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        disableDefaultUi: true
                    }"
                >
                    <GMapMarker v-if="locationPermission"
                        :draggable="false"
                        :position="realtimeMarker.position"
                        :icon="positionIcon"
                    />
                    <GMapMarker
                        :draggable="true"
                        :position="marker.position"
                        @dragend="updateMarkerPosition"
                        :icon="markerIcon"
                    />
                </GMapMap>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'InputCoordinatesModal',
    data() {
        return {
            map: null,
            center: null,
            marker: null,
            realtimeMarker: null,
            markerIcon: `${baseHref}` + '/src/shared/img/marker.svg', // eslint-disable-line
            positionIcon: `${baseHref}` + '/src/shared/img/blue-circle.svg', // eslint-disable-line
            eventListenersAdded: false,
            initialized: false
        }
    },
    props: [
        'coordinates',
        'yardCoordinates',
        'realtimeCoordinates',
        'modalVisible',
        'permission',
        'zoom'
    ],
    computed: {
        locationPermission() {
            return this.$store.getters['user/getLocationPermission'];
        }
    },
    mounted() {
        this.init();

        if (this.locationPermission) {
            this.initRealtimeCoordinates();
        }
    },
    watch: {

        realtimeCoordinates() {

            // when the realtime coordinates change change the position of the realtime marker
            const coordinates = JSON.parse(this.realtimeCoordinates);
            this.realtimeMarker = {
                id: this.realtimeCoordinates,
                position: {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude
                }
            };
        }
    },
    methods: {

        init () {

            let coordinates;

            if (this.coordinates) {
                coordinates = JSON.parse(this.coordinates);
            }
            else {
                coordinates = JSON.parse(this.yardCoordinates);
            }

            this.center = {
                lat: coordinates.latitude,
                lng: coordinates.longitude
            }

            this.marker = {
                id: 'marker',
                position: {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude
                }
            };

            if (this.realtimeCoordinates) {
                this.realtimeMarker = {
                    id: this.realtimeCoordinates,
                    position: {
                        lat: JSON.parse(this.realtimeCoordinates).latitude,
                        lng: JSON.parse(this.realtimeCoordinates).longitude
                    }
                };
            }

            this.initialized = true;
        },

        initRealtimeCoordinates () {

            this.$emit('update-realtime-coordinates');

            setInterval(() => {
                console.log('Refreshing location');
                this.$emit('update-realtime-coordinates');
            }, 10000);
        },
        /**
         * On dragend we retrieve the new coordinates and emit them to the InputCoordinates.vue component
         * @param e
         */
        updateMarkerPosition (e) {

            const coordinates = {
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
            }

            this.$emit('update-coordinates', coordinates);
        }
    }
}
</script>