const state = () => ({
    breadcrumbs: {}
});

const getters = {

    getBreadcrumbs(state) {
        return state.breadcrumbs;
    }
}

const mutations = {
    updateBreadcrumbs (state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
};