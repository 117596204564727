<template>
    <div v-if="form" class="form">

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <FormComponent
            :title="title"
            :groups="groups"
            :widgets="widgets"
            :error="hasErrors"
            :submitButtonLabel="submitButtonLabel"
            :actualValues="request"

            @submit-form="submitForm"
            @update-widget-in-form="updateRequest"
            @reset-error-boolean="hasErrors = false"
        />
    </div>
</template>

<script>
import FormMixin from "@/components/form/mixins/FormMixin";
import FormComponent from "@/components/form/Form";

export default {
    name: 'CoreDrillForm',
    components: {
        FormComponent
    },
    mixins: [FormMixin],
    data() {
        return {
            path: 'entry/' + this.entryType,
            params: "?yardId=" + this.yardId
        }
    },
    props: [
        'entryType',
        'yardId'
    ],
    created() {
        this.ajaxGetForm(this.path, this.params);
    },
    methods: {

        /**
         * Submit the form
         *
         * @returns {Promise<Response>}
         */
        async submitForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.post(JSON.stringify({ 'formValues': this.request }), this.path, this.params)
                .then(data => {

                    if (!data.success) {
                        this.$store.commit('loading/updateLoading', false);

                        // if we have errors we can replace our form with the form the backend sends back
                        this.form = data;
                        this.hasErrors = true;
                        this.scrollToTop();
                        return;
                    }

                    console.log('Post successful');

                    // when the post is successful we also need to update our store in this case
                    this.$store.commit('loading/updateLoading', false);
                    this.$router.push({ name: 'EntrySuccessView', query: { type: this.entryType } });
                });
        }
    }
}
</script>