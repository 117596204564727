import Animatable from "@/plugins/Animatable/Animatable.vue";

/**
 * Animatable
 *
 * Install the global component
 */
export default {
    install: (app) => { // eslint-disable-line
        app.component("Animatable", Animatable); // eslint-disable-line
    },
};