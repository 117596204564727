<template>
  <div class="header" ref="header">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12">
                  <div class="header__inner">
                      <img src="/src/shared/img/cgeolink-logo.svg" alt="CGeolink logo">
                      <a v-if="authenticated"
                         :class="{ 'header__inner__hamburger--open': hamburgerActive }"
                         class="header__inner__hamburger"
                         @click="activateHamburger">
                          <div class="hamburger__stripe hamburger-1"></div>
                          <div class="hamburger__stripe hamburger-2"></div>
                          <div class="hamburger__stripe hamburger-3"></div>
                      </a>
                  </div>
              </div>
          </div>
      </div>

      <Breadcrumbs />

      <CGMenu v-if="authenticated"
              :open="hamburgerActive"
              :headerHeight="headerHeight"
              @logout="hamburgerActive = false"
              @close-menu="hamburgerActive = false"
      />

  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import CGMenu from "@/components/CGMenu";

export default {
    name: 'CGHeader',
    components: {
        CGMenu,
        Breadcrumbs
    },
    computed: {
        authenticated() {
            // get the user from our store
            return this.$store.getters['user/getAuthenticated'];
        }
    },
    data() {
        return {
            hamburgerActive: false,
            headerHeight: 0,
            resizeObserver: null
        }
    },
    methods: {

        /**
         * Handle the hamburger menu
         */
        activateHamburger () {
            this.hamburgerActive = ! this.hamburgerActive;
        },

        setHeaderHeight() {
            const height = this.$refs.header.clientHeight;
            this.headerHeight = height;
            this.$emit('header-loaded', height);
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(this.setHeaderHeight);
        this.resizeObserver.observe(this.$refs.header);
        this.setHeaderHeight();
    }
}
</script>