<template>

    <div class="input-matrix" :data-state-input="id">

        <h2>{{ title }}</h2>

        <div class="input-matrix__heading">
            {{ label }}
        </div>
        <InputMatrixGrid
            :attributes="attributes"
            :value="value"
            @value-updated="updateWidget"
        />
    </div>
</template>

<script>
import FormElementMixin from "@/components/form/mixins/FormElementMixin";
import InputMatrixGrid from "@/components/form/inputs/custom/partials/InputMatrixGrid";

export default {
    name: 'InputMatrix',
    components: {
        InputMatrixGrid
    },
    mixins: [FormElementMixin],
    computed: {
        label() {
            return this.$store.getters['plateTest/getLabel'];
        }
    },
    methods: {

        /**
         * Custom update method, we don't want to update our request via an input event, instead update it at the end
         * of the updateAverage method
         */
        updateWidget (e) {

            const value = {
                value: e,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        }
    }
}
</script>