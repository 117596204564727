let auth = false;

if (`${isAuthenticated}` == 1) { // eslint-disable-line
    auth = true;
}

/**
 * Export our helper constant Auth, we can ask Auth if we are authenticated
 * When the app loads, we also use this helper in our store
 *
 * @type {{isAuthenticated: boolean}}
 */
export const Auth = {
    isAuthenticated: auth
};