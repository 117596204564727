<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <LoginForm />
                <div class="login-anchor">
                    <router-link :to="{ name: 'ResetPassword' }">
                        Wachtwoord vergeten?
                    </router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import LoginForm from "@/components/form/LoginForm";

export default {
    name: 'LoginView',
    components: {
        LoginForm,
    },
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.breadcrumbs.dashboard = 'Aanmelden'
        this.breadcrumbs.form = null;
        this.breadcrumbs.formStep = null;

        this.$store.commit('breadcrumbs/updateBreadcrumbs', this.breadcrumbs);
    },
}
</script>
