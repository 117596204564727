<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <div class="form-input__coordinates">

            <span v-if=" ! coordinates && permission === null" class="form-input__coordinates__icon form-input__coordinates__loading">
            </span>
            <span v-else-if="! coordinates && permission === false" class="form-input__coordinates__icon form-input__coordinates__icon--error">
            </span>
            <span v-else class="form-input__coordinates__icon form-input__coordinates__icon--ok">
            </span>

            <div v-if="permission === false">
              <span v-if="coordinates === null">Locatie kan niet automatisch bepaald worden.</span>
              <span v-else>Locatie manueel bepaald.</span>
                <br>
                <a @click="openModal">Stel locatie in op kaart</a>
            </div>
            <div v-else-if="coordinates === null">
                <span>Locatiegegevens ophalen...</span><br>
            </div>
            <div v-else>
                <span>Locatie automatisch bepaald via toestel.</span><br>
                <a @click="openModal">Bekijk/wijzig locatie op kaart</a>
            </div>

        </div>

        <InputCoordinatesModal v-if="modalVisible"
            :coordinates="coordinates"
            :yardCoordinates="yardCoordinates"
            :zoom="zoom"
            :realtimeCoordinates="realtimeCoordinates"
            :modalVisible="modalVisible"
            @update-coordinates="updateCoordinates"
            @hide-modal="hideModal"
            @update-realtime-coordinates="getRealtimeCoordinates"
        />

    </div>

    <input
        :class="{ 'input--error': error }"
        type="text"
        :value="coordinates"
        :name="id"
        :id="id"
        ref="input"
        hidden
    >
</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';
import InputCoordinatesModal from "@/components/form/inputs/custom/partials/InputCoordinatesModal";

export default {
    name: 'InputCoordinates',
    components: {InputCoordinatesModal},
    mixins: [FormElementMixin],
    data() {
        return {
            coordinates: null,
            realtimeCoordinates: null,
            modalVisible: false,
            permission: null,
            zoom: 17
        }
    },
    watch: {
        locationPermission() {
            this.getUserCoordinates();
        }
    },
    computed: {
        locationPermission() {
            return this.$store.getters['user/getLocationPermission'];
        },
        yard() {
            return this.$store.getters['yard/getYard'];
        }
    },
    mounted() {

        if (this.locationPermission === false) {
            this.noPermissionMode();
        }
    },
    methods: {

        /**
         * Custom update method, we don't update the request via an input event but when we receive our coordinates
         */
        updateWidget () {

            const value = {
                value: this.coordinates,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        },

        /**
         * Ask the Geolocation API what our coordinates are
         */
        getUserCoordinates () {

            this.permission = null;

            // ask user permission
            navigator.geolocation.getCurrentPosition(
                // if we have
                position => { // eslint-disable-line
                    const coordinates = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }

                    this.coordinates = JSON.stringify(coordinates);
                    this.realtimeCoordinates = this.coordinates;
                    this.permission = true;
                    this.updateWidget();
                },
                error => {
                    this.permission = false;
                    console.log(error.message);
                }
            );
        },

        /**
         * Get the coordinates again to update the realtime position
         */
        getRealtimeCoordinates () {

            // ask user permission
            navigator.geolocation.getCurrentPosition(
                // if we have
                position => { // eslint-disable-line

                    this.realtimeCoordinates = JSON.stringify({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    })
                },
                error => {
                    this.permission = false;
                    console.log(error.message);
                }
            );
        },

        /**
         * When the user changed the marker position on the google maps modal update the coordinates
         */
        updateCoordinates (coordinates) {

            this.coordinates = JSON.stringify(coordinates);
            this.updateWidget();
        },

        /**
         * Just open the modal
         */
        openModal () {
            this.modalVisible = true;
            this.$emit('hide-buttons', true);
        },

        /**
         * Hide the modal
         */
        hideModal () {
            this.modalVisible = false;
            this.$emit('hide-buttons', false);
        },

        noPermissionMode () {

            if (! this.locationPermission && this.yard.yardCoordinates) {

                const coordinates = {
                    latitude: this.yard.yardCoordinates.lat,
                    longitude: this.yard.yardCoordinates.lng
                }

                this.yardCoordinates = JSON.stringify(coordinates);
                this.zoom = 13
            }
            else if (! this.locationPermission && ! this.yard.yardCoordinates) {

                const coordinates = {
                    latitude: 50.84667,
                    longitude: 4.35472
                }

                this.yardCoordinates = JSON.stringify(coordinates);
                this.zoom = 8
            }
        }
    },
    created() {
        this.getUserCoordinates();
    }
}
</script>