<template>
    <div>
        <div ref="header">
            <CGHeader />
        </div>

        <div class="page-wrap" ref="pageWrap">
            <AppLoader :loading="loading" />
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import CGHeader from './components/CGHeader';
import AppLoader from "@/components/AppLoader";

export default {
    name: 'App',
    components: {
        AppLoader,
        CGHeader
    },
    watch: {
        authenticated() {
            // when the computed authenticated changes update it in the store
            this.$store.commit('user/updateAuthenticated', this.authenticated);
        },
        loading() {
            // temporarily end scrolling while loading
            this.noScrolling();
        }
    },
    computed: {
        authenticated() {
            // get the user from our store
            return this.$store.getters['user/getAuthenticated'];
        },
        loading() {
            return this.$store.getters['loading/getLoading'];
        },
        locationPermission() {
            return this.$store.getters['user/getLocationPermission'];
        },
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {

        if (this.authenticated) {
            this.checkForYardCookie();
        }

        this.checkPermissions();
        this.initBreadcrumbs();
    },
    created() {
        // check if we are authenticated
        this.ajaxGetAuthenticated();
    },
    methods: {

        /**
         * When our app is initialized, we check for location sharing permissions
         * Store the result.
         *
         * This is necessary because without location sharing this app doesn't really work :D
         */
        checkPermissions () {

            this.$store.commit('loading/updateLoading', true);

            // ask user permission
            navigator.geolocation.getCurrentPosition(
                position => { // eslint-disable-line
                    this.$store.commit('user/updateLocationPermission', true);
                    this.$store.commit('loading/updateLoading', false);
                },
                error => {
                    console.log(error.message);
                    this.$store.commit('user/updateLocationPermission', false);
                    this.$store.commit('loading/updateLoading', false);
                }
            );
        },

        /**
         * Disable or re-enable scrolling
         */
        noScrolling () {
            const body = document.querySelector('body');

            if (this.loading) {
                body.classList.add('no-scrolling');
            }
            else {
                body.classList.remove('no-scrolling');
            }
        },

        /**
         * End the loader
         */
        endLoading () {
            this.loading = false;
            this.noScrolling();
        },

        /**
         * Try to get the user and update the state
         *
         * @return boolean
         */
        async ajaxGetAuthenticated () {
            this.$api.user.authenticated().then(data => {
                this.$store.commit('user/updateAuthenticated', data.ok);
            });
        },

        /**
         * When the user is logged in, he/she is asked to select a yard to make entries for.
         * This yard is then stored in VueX.
         *
         * If the user refreshes the page and is still authenticated, the stored yard will be lost, that's why we store it in a cookie.
         * This way we can set the store on page load to ensure the user can continue where he/she left off :-)
         */
        checkForYardCookie () {
            const name = "selected-yard=";
            const cookies = decodeURIComponent(document.cookie);
            const cookieArray = cookies.split(';');

            cookieArray.forEach(cookie => {
                if (cookie.includes(name)) {
                    const object = JSON.parse(cookie.split(name).pop());
                    this.$store.commit('yard/updateYard', object);
                }
            });
        },

        initBreadcrumbs () {

            const breadcrumbs = {
                dashboard: 'Dashboard',
                form: null,
                formStep: null
            }

            this.$store.commit('breadcrumbs/updateBreadcrumbs', breadcrumbs);
        }
    }
}
</script>
