import { Api } from "@/api/Api";
import { Auth } from '@/auth/auth'

const state = () => ({
    user: {},
    authenticated: Auth.isAuthenticated,
    locationPermission: null
});

const getters = {

    getUser(state) {
        return state.user;
    },
    getAuthenticated(state) {
        return state.authenticated;
    },
    getLocationPermission(state) {
        return state.locationPermission;
    }
}

const actions = {

    async setUser({ state }) {
        state.user = await Api.user.authenticated();
    },
    async setAuthenticated({ state }, authenticated) {
        state.authenticated = authenticated;
    }
}

const mutations = {
    updateAuthenticated (state, authenticated) {
        state.authenticated = authenticated;
    },
    updateLocationPermission (state, locationPermission) {
        state.locationPermission = locationPermission;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};