<template>
    <div class="breadcrumbs" v-if="breadcrumbsObject">
        <div class="container-fluid">

            <div class="breadcrumbs__inner">

                <div>

                    <span v-if="breadcrumbs.dashboard !== null" class="breadcrumbs__item">
                        <router-link :to="{ name: 'EntryTypesView' }">{{ breadcrumbs.dashboard }}</router-link>
                    </span>

                    <span v-if="breadcrumbs.form !== null" class="breadcrumbs__item">
                        <i class="icon-chevron-right"></i>{{ breadcrumbs.form }}
                    </span>

                    <span v-if="breadcrumbs.formStep !== null" class="breadcrumbs__item">
                        <i class="icon-chevron-right"></i>{{ breadcrumbs.formStep }}
                    </span>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs-component',
    data() {
        return {
            breadcrumbsObject: null
        }
    },
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    watch: {
        breadcrumbs() {
            this.breadcrumbsObject = this.breadcrumbs;
        }
    }
}
</script>