<template>

    <div :data-state-input="id">
        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <input
            :class="{ 'input--error': error }"
            type="text"
            :value="average"
            :name="id"
            readonly

            @input="updateWidgetInForm"
        >
    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputAverage',
    mixins: [FormElementMixin],
    data() {
        return {
            fields: [],
            average: null
        }
    },
    mounted() {
        this.watchCalculateFields();
    },
    methods: {

        /**
         * Get the calculate fields we receive from our attributes and add eventListeners to them
         */
        watchCalculateFields () {

            this.attributes.calculateFields.forEach(field => {
                const calculateField = document.querySelector('#' + field);
                calculateField.oninput = this.updateAverage;

                this.fields.push(field);
            });
        },

        /**
         * When one of the calculateFields is updated, recalculate the average
         */
        updateAverage () {

            let newValue = 0;
            let fieldAmount = 0;

            this.fields.forEach(field => {
                const value = document.querySelector('#' + field).value;

                if (value) {
                    newValue += parseInt(value);
                    fieldAmount++;
                }
            });

            this.average = Math.floor(newValue / fieldAmount) ? Math.floor(newValue / fieldAmount) : null;
            this.updateWidget();
        },

        /**
         * Custom update method, we don't want to update our request via an input event, instead update it at the end
         * of the updateAverage method
         */
        updateWidget () {

            const value = {
                value: this.average,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        }
    }
}
</script>