<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ id }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <canvas id="canvas"
                class="signature-canvas"
                :width="width"
                :height="height"
                @endStroke="updateSignature"
        ></canvas><br>

        <button class="button--small button--white" ref="clearButton">
            Handtekening leegmaken
        </button>
        <br>
        <br>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';
import SignaturePad from "../../../../plugins/signature_pad/signature_pad.umd.min";

export default {
    name: 'InputSignature',
    mixins: [FormElementMixin],
    data() {
        return {
            width: 300,
            height: 260
        }
    },
    mounted() {
        this.initSignatureField();
    },
    methods: {

        /**
         * In the mounted hook we initialize our canvas and add an eventListener for the 'endStroke' event.
         * Whenever the user stops drawing on the canvas this event is called.
         * When the event is triggered, call the updateSignature method.
         */
        initSignatureField () {

            const canvas = document.querySelector("#canvas");
            const signaturePad = new SignaturePad(canvas, {
                penColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(255, 255, 255)'
            });

            signaturePad.addEventListener('endStroke', () => {
                this.updateSignature(signaturePad);
            });

            this.$refs.clearButton.addEventListener('click', () => {
                signaturePad.clear();

                const value = {
                    value: null,
                    id: this.id
                };

                this.$emit('update-widget-in-form', value);
            });

            if (this.value) {
                signaturePad.fromDataURL(this.value, { ratio: 1, width: this.width, height: this.height, xOffset: 0, yOffset: 0 });
            }
        },

        /**
         * Generates a base64 string of our signature image and emits a value object containing the base64 string and
         * the widget id
         *
         * @param signaturePad
         */
        updateSignature (signaturePad) {

            const base64 = signaturePad.toDataURL();

            const value = {
                value: base64,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        }
    }
}
</script>