const formElementMixin = {
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		type: {
			type: String,
			default: '',
			required: true,
		},
		helpText: {
			type: String,
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		hasError: {
			type: Boolean,
			required: true,
		},
		value: {
			type: String,
			required: false,
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		hidden: {
			type: Boolean,
			required: true,
		},
		isEmpty: {
			type: Boolean,
			required: true,
		},
		required: {
			type: Boolean,
			required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        selectOptions: {
            type: Object,
            required: false,
            default: {}
        },
        attributes: {
            type: Object,
            required: false,
            default: {}
        }
	},
	methods: {

        /**
         * On input we update the value of the current widget in the form component too :-)
         * We emit a small object with the current widget id and value
         * @param e
         */
		updateWidgetInForm (e) {

            const value = {
                value: e.target.value,
                id: this.id
            };

            // if for some reason we get a null string, make it null
            if (value.value === 'null') {
                value.value = null;
            }

            this.$emit('update-widget-in-form', value);
        }
	}
}

export default formElementMixin;