import UserRoutes from "@/api/routes/UserRoutes";
import FormRoutes from "@/api/routes/FormRoutes";
import EntryRoutes from "@/api/routes/EntryRoutes";

/**
 * API
 *
 * This module handles our API calls
 * Every call is defined in their own respective module (eg. form calls are defined in ./routes/FormRoutes.js etc..)
 *
 * We do this to keep everything tidy and organised, because nobody likes their AJAX calls defined in the code itself, right?
 *
 * Calls can easily be accessed like so:

     await this.$api.form.get('user/login')
     .then(data => {
        console.log(data);
     })
     .catch(error => {
         console.log(error);
     });

 *
 */
export const Api = {
    user: UserRoutes,
    form: FormRoutes,
    entry: EntryRoutes
};