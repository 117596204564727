<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <input
            :class="{ 'input--error': error }"
            :type="attributes.type ? attributes.type : 'text'"
            :value="value"
            :name="id"
            :readonly="readOnly"
            :id="id"

            @click="updateWidgetInForm"
            @input="updateWidgetInForm"
        >

        <p class="help-text">
            {{ helpText }}
        </p>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputText',
    mixins: [FormElementMixin]
}
</script>