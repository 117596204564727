<template>

    <div v-if="form && ! successText" class="form">

        <h2>{{ title }}</h2>

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <form @submit.prevent>
            <div v-for="widget in widgets" :key="widget.id">

                <InputComponent
                    :widget="widget"
                    @update-widget-in-form="updateRequest"
                />
            </div>

            <button @click="submitForm">
                <span>{{ submitButtonLabel }}</span> <i class="icon-arrow"></i>
            </button>
        </form>
    </div>
    <div v-else-if="errorMessage" class="reading">

        <h2>{{ errorMessage }}</h2>
        <router-link :to="{ name: 'Login' }" class="button">
            <span>Ga terug</span><i class="icon-arrow"></i>
        </router-link>
    </div>

    <div v-if="successText" class="reading">

        <h2>{{ successText }}</h2>
        <router-link :to="{ name: 'Login' }" class="button">
            <span>{{ successLabel }}</span><i class="icon-arrow"></i>
        </router-link>
    </div>

</template>

<script>
import FormMixin from './mixins/FormMixin.js';
import InputComponent from "@/components/form/inputs/Input";

export default {
    name: 'RecoverPasswordForm',
    components: {
        InputComponent
    },
    mixins: [FormMixin],
    data() {
        return {
            token: null,
            errorMessage: null,
            successText: null,
            successLabel: null
        }
    },
    methods: {

        /**
         * Call our form via ajax, called in the created hook
         *
         * @returns {Promise<Response>}
         */
        async ajaxGetForm() {

            this.$store.commit('loading/updateLoading', true);
            this.token = this.$route.query.token;
            console.log(this.token)

            return await this.$api.form.get('user/recover-password?token=' + this.token)
                .then(data => {

                    if ( ! data.success) {
                        this.errorMessage = data.message;
                        return;
                    }

                    this.form = data;

                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    this.$store.commit('loading/updateLoading', false);
                    console.log(error);
                });
        },

        /**
         * Submit the form
         *
         * @returns {Promise<Response>}
         */
        async submitForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.post(JSON.stringify({ 'formValues': this.request }), 'user/recover-password', '?token=' + this.token)
                .then(data => {

                    if (!data.success) {
                        this.$store.commit('loading/updateLoading', false);

                        // if we have errors we can replace our form with the form the backend sends back
                        return this.form = data;
                    }

                    console.log('Post successful');

                    // when the post is successful we also need to update our store in this case
                    this.successText = `${passwordRecoveryText}`; // eslint-disable-line
                    this.successLabel = `${passwordRecoveryLabel}`; // eslint-disable-line
                    this.$store.commit('loading/updateLoading', false);
                });
        }
    },
    created() {
        this.ajaxGetForm();
    }
}
</script>