import { createStore } from 'vuex';
import user from './modules/user';
import loading from './modules/loading';
import yard from './modules/yard';
import breadcrumbs from './modules/breadcrumbs';
import plateTest from './modules/plate-test';
import houseConnection from './modules/house-connection';

export default createStore({
    modules: {
        user: user,
        loading: loading,
        yard: yard,
        breadcrumbs: breadcrumbs,
        plateTest: plateTest,
        houseConnection: houseConnection
    }
})