<template>

    <component
        :is="widget.type"
        :id="widget.id"
        :type="widget.type"
        :title="widget.formControl.title"
        :read-only="widget.formControl.readOnly"
        :required="widget.formControl.required"
        :hidden="widget.formControl.hidden"
        :has-error="widget.formControl.hasError"
        :value="widget.formControl.value"
        :helpText="widget.formControl.helpText"
        :error="widget.formControl.error"
        :select-options="widget.formControl.selectOptions ? widget.formControl.selectOptions : {}"
        :attributes="widget.attributes"

        class="input"
    >
    </component>

</template>

<script>

/**
 * Inputs are handled via the <component> tag above, this tag will be replaced with the tag we receive from the backend.
 * Input types are configured in CustomInputs.js !! So if you ever need to add a new control type CustomInputs.js is where it's at :-)
 *
 * All the inputs use the [FormElementMixin] mixin, this mixin, just like in the form mixin, contains everything we need to make
 * sure our input work properly.
 * Custom stuff can still be added in their respective templates if needed :-) the mixins kinda act like interfaces
 */
export default {
    name: 'InputComponent',
    props: {
        widget: {
            type: Object,
            required: true,
        }
    }
}
</script>