<template>

    <div :data-state-input="id">

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <div class="select-input-wrapper">
            <select :name="id"
                    :id="id"
                    @input="updateWidgetInForm"
                    @change="storeUpdates"
                    :class="{ 'input--error': error }"
                    ref="select"
                    :disabled="readOnly"
            >
                <option value="null">--</option>
                <option v-for="value in selectOptions" :value="value.value" :key="value.value" :selected="value.value === this.value">
                    {{ value.label }}
                </option>
            </select>
            <i class="icon-chevron-down"></i>
        </div>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputSelect',
    mixins: [FormElementMixin],
    computed: {
        label() {
            return this.$store.getters['plateTest/getLabel'];
        },
        delta() {
            return this.$store.getters['plateTest/getDelta'];
        },
        material() {
            return this.$store.getters['houseConnection/getMaterial'];
        }
    },
    mounted() {

        /**
         * Edge case, when on the house connection form, auto fill specific field
         */
        if (this.id === `${houseConnectionMaterialId}` && this.material) {
            this.$refs.select.value = this.material;
        }
    },
    methods: {

        storeUpdates (e) {
            this.plateTestUpdateStore(e);
            this.houseConnectionUpdateStore(e);
        },

        /**
         * When we are in the plate installation form, we need to store which type we selected
         * because we need to know this in the matrix widget
         *
         * @param e
         */
        plateTestUpdateStore (e) {

            if (this.id === `${plateTestFormPlateId}`) {

                const selectedWidget = this.selectOptions.find(x => x.value == e.target.value);

                console.log(selectedWidget)
                this.$store.commit('plateTest/updateDelta', e.target.value);
                this.$store.commit('plateTest/updateLabel', selectedWidget.label);
            }
        },

        /**
         * On the house connection form we store the selected material type so when we load the modal again
         * we can automatically set the value we previously selected
         *
         * @param e
         */
        houseConnectionUpdateStore (e) {

            if (this.id === `${houseConnectionMaterialId}`) {

                const selectedWidget = this.selectOptions.find(x => x.value == e.target.value);

                this.$store.commit('houseConnection/updateMaterial', e.target.value);
            }
        }
    }
}
</script>