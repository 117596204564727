import { ApiConfig } from '../ApiConfig.js';
import store  from '../../store/index';

/**
 * FormRoutes
 *
 * All available requests for getting or posting forms from the backend
 */
export default {

    /**
     * GET
     *
     * @param path
     * @param params
     * @returns {Promise<Response>}
     */
    get(path, params = '') {

        return fetch(ApiConfig.baseURL + '/ajax/form/' + path + params, {
            method: 'GET',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    /**
     * POST
     *
     * @param data
     * @param path
     * @param params
     * @param locale
     * @returns {Promise<Response>}
     */
    post(data, path, params = '', locale = 'nl') {

        // check if a locale is set
        if (store.getters['language/getLanguage']) {
            locale = store.getters['language/getLanguage'];
        }

        return fetch(ApiConfig.baseURL + '/' + locale + '/ajax/form/' + path + '/post' + params, {
            method: 'POST',
            headers: ApiConfig.headers,
            body: data
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    /**
     * VALIDATE
     *
     * @param data
     * @param path
     * @param locale
     * @returns {Promise<Response>}
     */
    validate(data, path, locale = 'nl') {

        // check if a locale is set
        if (store.getters['language/getLanguage']) {
            locale = store.getters['language/getLanguage'];
        }

        return fetch(ApiConfig.baseURL + '/' + locale + '/ajax/form/' + path + '/validate-control', {
            method: 'POST',
            headers: ApiConfig.headers,
            body: data
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    media(data) {

        return fetch(ApiConfig.baseURL + '/nl/ajax/media/upload', {
            method: 'POST',
            headers: ApiConfig.headers,
            body: data
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },
}