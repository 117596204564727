<template>

    <div v-if="form" class="form">

        <h2>{{ title }}</h2>

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <div v-for="widget in widgets" :key="widget.id">

            <InputComponent
                :widget="widget"
                @update-widget-in-form="updateRequest"
            />
        </div>

        <button @click="submitForm">
            <span>{{ submitButtonLabel }}</span> <i class="icon-arrow"></i>
        </button>
    </div>

</template>

<script>
import FormMixin from './mixins/FormMixin.js';
import InputComponent from "@/components/form/inputs/Input";

export default {
    name: 'SelectYardForm',
    components: {
        InputComponent
    },
    mixins: [FormMixin],
    data() {
        return {
            yardCoordinates: null
        }
    },
    computed: {
        yard() {
            return this.$store.getters['yard/getYard'];
        }
    },
    watch: {
        yardCoordinates() {
            this.updateYardObject();
        }
    },
    methods: {

        /**
         * Call our form via ajax, called in the created hook
         *
         * @returns {Promise<Response>}
         */
        async ajaxGetForm() {
            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.get('entry/selectYard')
                .then(data => {
                    this.form = data;
                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    this.$store.commit('loading/updateLoading', false);
                    console.log(error);
                });
        },

        /**
         * "Submit" the form
         */
        async submitForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.post(JSON.stringify({ 'formValues': this.request }), 'entry/selectYard')
                .then(data => {

                    if (!data.success) {
                        this.$store.commit('loading/updateLoading', false);

                        // if we have errors we can replace our form with the form the backend sends back
                        return this.form = data;
                    }

                    console.log('Post successful');

                    const yardObject = this.createYardObject();
                    this.getYardCoordinates(yardObject.yardCity);

                    this.setYardCookie(yardObject);

                    // store the selected yard and the date for easy access B-)
                    this.$store.commit('yard/updateYard', yardObject);
                });
        },

        /**
         * Get the coordinates of the city of our yard
         */
        async getYardCoordinates (city) {
            this.$store.commit('loading/updateLoading', true);

            return await this.$api.user.yardCoordinates(city)
                .then(data => {
                    this.yardCoordinates = data.results[0].geometry.location;
                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    console.log(error);
                    this.$router.push({ name: 'EntryTypesView' });
                });
        },

        /**
         *  Return an object with all necessary information about our selected yard
         *  This is also the object that will be stored in our store
         */
        createYardObject () {

            // get the widget we want data from, in this case the widget with id 'yard'
            const widget = this.form.form.widgets.filter(object => {
                return object.id === 'yard'
            });

            // now get the selected option
            const value = widget[0].formControl.selectOptions.filter(option => {
                return option.value === parseInt(this.request.yard);
            });

            const city = widget[0].attributes[value[0].value];

            return {
                'yardId': value[0].value,
                'yardName': value[0].label,
                'date': this.request.date,
                'yardCity': city,
                'yardCoordinates': null
            }
        },

        /**
         * because we also get coordinates via the google maps api, we overwrite our yard and add the coordinates when
         * we receive them
         */
        updateYardObject () {

            const yardObject = {
                'yardId': this.yard.yardId,
                'yardName': this.yard.yardName,
                'date': this.yard.date,
                'yardCity': this.yard.yardCity,
                'yardCoordinates': null
            };

            if (this.yardCoordinates) {

                yardObject.yardCoordinates = this.yardCoordinates;

                this.setYardCookie(yardObject);

                // store the selected yard and the date for easy access B-)
                this.$store.commit('yard/updateYard', yardObject);
                this.$router.push({ name: 'EntryTypesView' });
            }
        },

        /**
         * When submitting this form we set the selected yard in the store
         * When we refresh we lose said store, that's why we also store our yard in a cookie :-)
         * This way we can easily reset the yard in the store
         */
        setYardCookie (yardObject) {

            const date = new Date();
            date.setTime(date.getTime() + (30*24*60*60*1000));
            let expires = "expires="+ date.toUTCString();

            document.cookie = "selected-yard=" + JSON.stringify(yardObject) + ";" + expires + ";path=/";
        }
    },
    created() {
        this.ajaxGetForm();
    }
}
</script>