<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <input
            :class="{ 'input--error': error }"
            type="number"
            :value="value"
            :name="id"
            :id="id"
            min="0"

            @input="updateWidgetInForm"
        >

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputNumber',
    mixins: [FormElementMixin]
}
</script>