<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <div class="uploaded-images-wrapper">
            <span class="uploaded-image-wrapper" v-for="image in imagesArray" :key="image.id" :ref="image.id">
                <span class="uploaded-image-delete" title="Foto verwijderen" @click="removeImage(image.id)">
                    <i class="icon-close"></i>
                </span>
                <img class="uploaded-image" :src="image.thumbnail" alt="Opgeladen foto">
            </span>
            <span @click="toggleUpload" title="Foto opladen" class="uploaded-images__add">
                <i class="icon-foto"></i>
            </span>
        </div>

        <input
            :class="{ 'input--error': error }"
            type="file"
            :accept="attributes.accept"
            :name="id"
            :id="id"
            ref="upload"
            multiple
            hidden

            @change="uploadImages"
        >
        <span @click="uploadImages" id="uploadTrigger"></span>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputUpload',
    mixins: [FormElementMixin],
    data() {
        return {
            images: [],
            imagesArray: this.value
        }
    },
    mounted() {

        // if the imagesArray is null, we need to make it an array
        if (this.imagesArray === null) {

            this.imagesArray = [];
        }
    },
    methods: {

        async uploadImages () {

            this.images = this.$refs.upload.files;

            const formData = new FormData();

            // add every uploaded image to the formData
            for (let i = 0; i < this.images.length; i++) {
                const image = this.images[i];
                formData.append('files[]', image);
            }

            // get and array with thumbnail paths for our images
            const result = await this.$api.form.media(formData);
            this.imagesArray = [...this.imagesArray, ...result.data];

            this.updateImagesInForm();
        },

        /**
         * Custom emit function
         */
        updateImagesInForm () {

            const value = {
                value: this.imagesArray,
                id: this.id
            };

            this.$emit('update-widget-in-form', value);
        },

        removeImage (imageId) {

            // delete animation
            this.$refs[imageId][0].classList.add('delete-image');

            // delete from array when animation is done playing
            this.$refs[imageId][0].addEventListener('animationend', () => {
                const result = this.imagesArray.find( ({ id }) => id === imageId );
                this.imagesArray.splice(this.imagesArray.indexOf(result), 1);

                this.updateImagesInForm();
            });
        },

        toggleUpload () {
            this.$refs.upload.click();
        }
    }
}
</script>