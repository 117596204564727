<template>

    <div v-if="types" class="form">

        <h2>{{ title }}</h2>

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <Animatable v-for="type in types" :key="type">
            <template #animate>
                <router-link :to="'create-entry/' + type.slug">
                    <button class="button--big-icon">
                        <span><i :class="'icon-' + type.slug"></i> <b>{{ type.label }}</b></span>
                    </button>
                </router-link>
            </template>
        </Animatable>

    </div>

</template>

<script>
import FormMixin from './mixins/FormMixin.js';

export default {
    name: 'EntryTypesForm',
    mixins: [FormMixin],
    data() {
        return {
            loading: true,
            types: null
        }
    },
    computed: {
        yard() {
            return this.$store.getters['yard/getYard'];
        }
    },
    methods: {

        /**
         * Call our types via ajax
         *
         * @returns {Promise<Response>}
         */
        async ajaxGetForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.entry.entryTypes('yardId=' + this.yard.yardId)
                .then(data => {
                    this.types = data.data;
                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    this.$store.commit('loading/updateLoading', false);
                    console.log(error);
                });
        }
    },
    mounted() {
        this.ajaxGetForm();
    }
}
</script>