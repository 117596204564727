<template>

    <div class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <SelectYardForm />
            </div>
        </div>
    </div>

</template>

<script>
import SelectYardForm from "@/components/form/SelectYardForm";

export default {
    name: 'YardSelectView',
    components: {
        SelectYardForm
    },
    computed: {
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.breadcrumbs.dashboard = 'Dashboard'
        this.breadcrumbs.form = null;
        this.breadcrumbs.formStep = null;

        this.$store.commit('breadcrumbs/updateBreadcrumbs', this.breadcrumbs);
    },
}
</script>