import { ApiConfig } from '../ApiConfig.js';

/**
 * UserRoutes
 *
 * All available requests for users
 */
export default {

    /**
     * DELETE
     *
     * @returns {Promise<Response>}
     */
    delete() {
        return fetch(ApiConfig.baseURL + '/ajax/user/delete', {
            method: 'POST',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    /**
     * AUTHENTICATED
     *
     * @returns {Promise<Response>}
     */
    authenticated() {
        return fetch(ApiConfig.baseURL + '/ajax/user/getAuthenticated', {
            method: 'GET',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                return response;
            });
    },

    /**
     * LOGOUT
     *
     * @returns {Promise<Response>}
     */
    logout() {
        return fetch(ApiConfig.baseURL + '/ajax/user/logout', {
            method: 'POST',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    /**
     * LOCALE
     *
     * @param locale
     * @returns {Promise<Response>}
     */
    locale(locale) {
        return fetch(ApiConfig.baseURL + '/ajax/user/set-locale/' + locale, {
            method: 'POST',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },

    /**
     * YARD COORDINATES
     *
     * @param city
     * @returns {Promise<Response>}
     */
    yardCoordinates(city) {

        return fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + city + '&key=' + `${googleMapsKey}`, { // eslint-disable-line
            method: 'GET',
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    }
}