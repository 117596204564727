import * as Vue from "vue/dist/vue.esm-bundler.js";

import InputText from '@/components/form/inputs/custom/InputText'
import InputPassword from '@/components/form/inputs/custom/InputPassword'
import InputSelect from "@/components/form/inputs/custom/InputSelect";
import InputDate from "@/components/form/inputs/custom/InputDate";
import InputNumber from "@/components/form/inputs/custom/InputNumber";
import InputTextarea from "@/components/form/inputs/custom/InputTextarea";
import InputAverage from "@/components/form/inputs/custom/InputAverage";
import InputSelectedYard from "@/components/form/inputs/custom/InputSelectedYard";
import InputSelectedDate from "@/components/form/inputs/custom/InputSelectedDate";
import InputCoordinates from "@/components/form/inputs/custom/InputCoordinates";
import InputImage from "@/components/form/inputs/custom/InputImage";
import InputCheckbox from "@/components/form/inputs/custom/InputCheckbox";
import InputUpload from "@/components/form/inputs/custom/InputUpload";
import InputRadio from "@/components/form/inputs/custom/InputRadio";
import InputMultiple from "@/components/form/inputs/custom/InputMultiple";
import InputSignature from "@/components/form/inputs/custom/InputSignature";
import InputMatrix from "@/components/form/inputs/custom/InputMatrix";
import InputMatrixToggle from "@/components/form/inputs/custom/InputMatrixToggle";

/**
 * Custom form inputs
 *
 * Install the custom form inputs
 */
export default {
    install: (app) => { // eslint-disable-line

        app.component('ak-input-text', InputText);
        Vue.compile('<ak-input-text />');

        app.component('ak-input-password', InputPassword);
        Vue.compile('<ak-input-password />');

        app.component('ak-select', InputSelect);
        Vue.compile('<ak-select />');

        app.component('ak-date-picker', InputDate);
        Vue.compile('<ak-date-picker />');

        app.component('ak-number', InputNumber);
        Vue.compile('<ak-number />');

        app.component('ak-textarea', InputTextarea);
        Vue.compile('<ak-textarea />');

        app.component('ak-average', InputAverage);
        Vue.compile('<ak-average />');

        app.component('ak-pinpoint', InputCoordinates);
        Vue.compile('<ak-pinpoint />');

        app.component('ak-upload-media', InputImage);
        Vue.compile('<ak-upload-media />');

        app.component('ak-checkbox', InputCheckbox);
        Vue.compile('<ak-checkbox />');

        app.component('ak-selected-yard', InputSelectedYard);
        Vue.compile('<ak-selected-yard />');

        app.component('ak-selected-date', InputSelectedDate);
        Vue.compile('<ak-selected-date />');

        app.component('ak-upload-image', InputUpload);
        Vue.compile('<ak-upload-image />');

        app.component('ak-radio', InputRadio);
        Vue.compile('<ak-radio />');

        app.component('ak-multiple', InputMultiple);
        Vue.compile('<ak-multiple />');

        app.component('ak-signature', InputSignature);
        Vue.compile('<ak-signature />');

        app.component('ak-matrix', InputMatrix);
        Vue.compile('<ak-matrix />');

        app.component('ak-toggle-matrix', InputMatrixToggle);
        Vue.compile('<ak-toggle-matrix />');
    }
};