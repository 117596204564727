const state = () => ({
    material: null,
});

const getters = {

    getMaterial(state) {
        return state.material;
    }
}

const mutations = {

    updateMaterial (state, material) {
        state.material = material;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
};