<template>

    <div :data-state-input="id">

        <label :for="id">
            {{ title }}<i v-if="required">*</i>
        </label>

        <p v-if="error" class="form-input-error">
            {{ error }}
        </p>

        <textarea :class="{ 'input--error': error }"
                  type="text"
                  :value="value"
                  :name="id"
                  cols="30" rows="4"
                  :id="id"

                  @input="updateWidgetInForm"
        >
        </textarea>

    </div>

</template>

<script>
import FormElementMixin from '../../mixins/FormElementMixin.js';

export default {
    name: 'InputTextarea',
    mixins: [FormElementMixin],
    created() {
        console.log()
    }
}
</script>