<template>

    <div class="selected-yard-wrapper">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <SelectedYard />
            </div>
        </div>
    </div>

    <div class="content">

        <div class="row justify-content-center">
            <div class="col-md-8">
                <EntryTypesForm />
            </div>
        </div>
    </div>

</template>

<script>
import EntryTypesForm from "@/components/form/EntryTypesForm";
import SelectedYard from "@/components/partials/SelectedYard";

export default {
    name: 'EntryTypesView',
    components: {
        SelectedYard,
        EntryTypesForm
    },
    computed: {
        yard() {
            return this.$store.getters['yard/getYard'];
        },
        breadcrumbs() {
            return this.$store.getters['breadcrumbs/getBreadcrumbs'];
        }
    },
    mounted() {
        this.breadcrumbs.dashboard = 'Dashboard'
        this.breadcrumbs.form = null;
        this.breadcrumbs.formStep = null;

        this.$store.commit('breadcrumbs/updateBreadcrumbs', this.breadcrumbs);

        window.scrollTo(0, 0);
    },
    created() {
        // if no yard is selected, redirect to select yard page
        if ( ! this.yard.yardId) {
            this.$router.push({ name: 'YardSelect' });
        }
    }
}
</script>