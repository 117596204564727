<template>

    <div v-if="form" class="form">

        <h2>{{ title }}</h2>

        <div v-if="error" class="form-error">
            {{ error }}
        </div>

        <form @submit.prevent>
            <div v-for="widget in widgets" :key="widget.id">

                <InputComponent
                    :widget="widget"
                    @update-widget-in-form="updateRequest"
                />
            </div>

            <button @click="submitForm">
                <span>{{ submitButtonLabel }}</span> <i class="icon-arrow"></i>
            </button>
        </form>
    </div>

</template>

<script>
import FormMixin from './mixins/FormMixin.js';
import InputComponent from "@/components/form/inputs/Input";

export default {
    name: 'LoginForm',
    components: {
        InputComponent
    },
    mixins: [FormMixin],
    methods: {

        /**
         * Call our form via ajax, called in the created hook
         *
         * @returns {Promise<Response>}
         */
        async ajaxGetForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.get('user/login')
                .then(data => {
                    this.form = data;
                    this.$store.commit('loading/updateLoading', false);
                })
                .catch(error => {
                    this.$store.commit('loading/updateLoading', false);
                    console.log(error);
                });
        },

        /**
         * Submit the form
         *
         * @returns {Promise<Response>}
         */
        async submitForm() {

            this.$store.commit('loading/updateLoading', true);

            return await this.$api.form.post(JSON.stringify({ 'formValues': this.request }), 'user/login')
                .then(data => {

                    if (!data.success) {
                        this.$store.commit('loading/updateLoading', false);

                        // if we have errors we can replace our form with the form the backend sends back
                        return this.form = data;
                    }

                    console.log('Post successful');

                    // when the post is successful we also need to update our store in this case
                    this.$store.commit('user/updateAuthenticated', true);
                    this.$router.push({ name: 'YardSelect' });
                });
        }
    },
    created() {
        this.ajaxGetForm();
    }
}
</script>